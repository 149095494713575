import React from 'react'
import * as S from './styles'
import CardsInfo from './CardsInfo.json'
import * as Button from '../../components/Buttons/styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'
import IconsSwitch from 'src/components/IconsSwitch/_index'

type CardsInfoProps = {
  icon: string;
  title: string;
  text: string;
  lib: string;
  customPath: string;
}

const BeneficiosParaConsultores = ({ setIsModalOpen }: SectionsProps) => {
  const [ sendDataLayerEvent ] = useDataLayer()

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='col-12 p-0'>
          <h2>Benefícios para consultores que precisam gerenciar multicarteiras</h2>
          <p className='text'>Gerencie o portfólio de clientes em um ambiente seguro e com uma ferramenta em tempo real.</p>
          <S.CardContainer>
            {CardsInfo.map((item: CardsInfoProps) => (
              <S.Card
                key={`card${CardsInfo.indexOf(item)}`}
              >
                <div className='cardTitleDiv'>
                  <div
                    className='iconDiv'
                  >
                    <IconsSwitch
                      icon={item.icon}
                      lib={item.lib as 'interco' | 'frontend-lib' | 'customInterco'}
                      customPath={item.customPath}
                      size='MD'
                      width='20'
                      height='20'
                      color='black'
                      className='icon'
                    />
                  </div>
                  <h3
                    className='cardTitle'
                  >
                    {item.title}
                  </h3>
                </div>
                <p
                  className='cardText'
                >
                  {item.text}
                </p>
              </S.Card>
            ))}
          </S.CardContainer>
          <Button.White
            className='mx-md-auto button'
            onClick={() => {
              setIsModalOpen(true)
              sendDataLayerEvent({
                section: 'dobra_02',
                section_name: 'Benefícios para consultores que precisam gerenciar multicarteiras',
                element_name: 'Quero cadastrar',
                element_action: 'click button',
              })
            }}
          >
            Quero cadastrar
          </Button.White>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default BeneficiosParaConsultores
