import React from 'react'
import * as S from './styles'
import CardsInfo from './CardsInfo.json'
import { grayscale } from 'src/styles/colors'
import IconsSwitch from '../../../../../../components/IconsSwitch/_index'
import { Button } from '@interco/inter-ui/components/Button'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'

type CardInfoProps = {
  icon: string;
  lib: string;
  title: string;
  text: string;
  customPath?: string;
}

const MaiorPortifolioDeProdutos = ({ setIsModalOpen }: SectionsProps) => {
  const width = useWidth()
  const [ sendDataLayerEvent ] = useDataLayer()

  const cardsMap = CardsInfo.map((item: CardInfoProps) =>
    <S.Card key={`card${item.icon}`}>
      <div className='iconDiv'>
        <IconsSwitch
          icon={item.icon}
          size='MD'
          width='20'
          height='20'
          color={grayscale[500]}
          lib={item.lib as 'interco' | 'frontend-lib' | 'customInterco'}
          className='icon'
          customPath={item.customPath}
        />
        {
          width >= 768
            ? <h3 className='cardTitle'>{item.title}</h3>
            : ''
        }
      </div>
      <div className='cardTextDiv'>
        {
          width < 768
            ? <h3 className='cardTitle'>{item.title}</h3>
            : ''
        }
        <p className='cardText'>
          {item.text}
        </p>
      </div>
    </S.Card>,
  )

  return (
    <S.ContentHeader>
      <S.DivPrincipal className='p-md-0 container'>
        <div className='p-0 col-12 col-md-6 col-lg-5 col-xl-4 textsDiv'>
          <h2 className='title'>
            Maior portfólio de produtos com base em segmentação
          </h2>
          <p className='text'>
            Gerencie portfólios personalizados e inteligentes de acordo com cada cliente e em tempo real.
          </p>
          {
            width < 768
              ? cardsMap
              : ''
          }
          <Button
            fullWidth
            onClick={() => {
              setIsModalOpen(true)
              sendDataLayerEvent({
                section: 'dobra_03',
                section_name: 'Maior portfólio de produtos com base em segmentação',
                element_name: 'Quero saber mais',
                element_action: 'click button',
              })
            }}
          >
            Quero saber mais
          </Button>
        </div>
        {
          width >= 768
            ? <div className='p-md-0 col-md-6 col-lg-7 col-xl-6 cardsDiv'>{cardsMap}</div>
            : ''
        }
      </S.DivPrincipal>

    </S.ContentHeader>
  )
}

export default MaiorPortifolioDeProdutos
