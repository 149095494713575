import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[100]};

  @media (min-width: 1024px){
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    padding: 93px 0;
  }

  h2,h3{ 
    font-family: "Citrina", Helvetica, sans-serif;
    font-weight: 700;
    color: ${grayscale[500]};
  }
`

export const DivPrincipal = styled.div`
  @media (min-width: 1024px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title{
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    margin: 0;

    @media (min-width: 1024px){
      font-size: 40px;
      line-height: 44px;
    }

    @media ${device.desktopXXL}{
      font-size: 48px;
      line-height: 52.8px;
    }
  }

  .button{
    width: 100%;

    @media ${device.tablet}{
      width: 60%;
      display: flex;
      margin: 0 auto;
    }

    @media (min-width: 1024px){
      width: 50%;
    }

    @media ${device.desktopXXL}{
      width: 40%;
    }
  }
`

export const Card = styled.article`
  background-color: white;
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  padding: 26.5px;
  width: 100%;
  margin: 24px 0;

  @media ${device.tablet} {
    padding: 43.5px 0;
    width: 47%;
    margin: 20px 0 ;
    padding: 53.5px;
  }

  @media ${device.desktopXXL}{
    width: 23%;
    padding: 64px 0;
  }

  .cardTitle{
    font-family: "Citrina", Helvetica, sans-serif;
    font-weight: 700;
    color: ${grayscale[500]};
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 8px;

    @media ${device.tablet}{
      font-size: 48px;
      line-height: 52.8px;
    }

    .spanCardTitle{
      font-size: 24px;
      line-height: 28.8px;
      text-align: center;
      margin: 0 0 0 8px;

      @media ${device.tablet}{
        font-size: 28px;
        line-height: 33.6px;
      }
    }
  }
  .text{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin: 0;

    @media ${device.tablet}{
      font-size: 16px;
      line-height: 19.36px;
    }
  }
`

export const CardsDiv = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
  }

  @media (min-width: 1024px){
    width: 80%;
    margin: 28px 0;
  }

  @media ${device.desktopXXL}{
    width: 100%;
    margin: 40px 0;
  }

`
